<template>
  <div class="pgBox myPage">
    <div class="reportTitle">体格检查报告</div>
    <div class="infoBox" v-if="info">
      <div class="heightBox">
        <div class="textBox1">
          <p
            v-for="item in hInfo.scaleInfo"
            :key="item.name"
            :style="'top:' + item.bfb + '%'"
          >
            {{ item.name || "" }}
          </p>
        </div>
        <img
          src="../../assets/images/height.png"
          style="width: 16px; height: 215px"
        />
        <div class="textBox2">
          <p
            v-for="item in hInfo.scaleInfo"
            :key="item.number"
            :style="'top:' + item.bfb + '%'"
          >
            {{ item.number || "" }}
          </p>
        </div>
        <div class="zz">
          <div class="zzInfo" :style="'top:' + hInfo.bfb + '%;'">
            <img
              src="../../assets/images/zz.png"
              mode="widthFix"
              style="width: 11px"
            />
          </div>
        </div>
        <div class="heightInfo">
          <p>
            <span style="font-size: 20px">{{ hInfo.myHeight || "" }}</span> cm
          </p>
          <div>身高</div>
        </div>
      </div>
      <div class="weightBox">
        <div class="weightImgBox">
          <img
            src="../../assets/images/weight.png"
            mode="widthFix"
            style="width: 160px"
          />
          <div
            class="zzBox"
            :style="'transform: rotateZ(' + wInfo.deg + 'deg);'"
          >
            <img
              src="../../assets/images/zz2.png"
              mode="widthFix"
              style="width: 16px"
              class="zz2"
            />
          </div>
        </div>

        <div class="weightInfo">
          <span style="font-size: 20px">{{ wInfo.myWeight || "" }}</span
          >kg
        </div>
        <h2>体重</h2>
        <div class="textBox1">
          <span class="s1">肥胖</span>
          <span class="s2">偏胖</span>
          <span class="s3">偏瘦</span>
          <span class="s4">标准</span>
        </div>
        <div class="textBox2">
          <span class="s1" v-if="wInfo.scaleInfo"
            >{{ wInfo.scaleInfo[0].number }}kg</span
          >
          <span class="s2" v-if="wInfo.scaleInfo"
            >{{ wInfo.scaleInfo[1].number }}kg</span
          >
          <span class="s3" v-if="wInfo.weightArr"
            >{{ wInfo.weightArr[2] }}kg</span
          >
          <span class="s4" v-if="wInfo.weightArr"
            >{{ wInfo.weightArr[3] }}kg</span
          >
          <span class="s5" v-if="wInfo.weightArr"
            >{{ wInfo.weightArr[4] }}kg</span
          >
        </div>
      </div>
    </div>
    <div class="contain">
      <h2><img src="../../assets/images/foot.png" alt="" />足踝检查</h2>
      <div class="content">
        <div class="checkBox">
          <div class="check" v-for="item in dataList" :key="item.name">
            <img
              v-if="item.checked"
              src="@/assets/images/checked.png"
              style="width: 20px"
              alt=""
            />
            <img
              v-else
              src="@/assets/images/check.png"
              style="width: 20px"
              alt=""
            />
            <span>{{ item.name }}</span>
          </div>
        </div>
        <!-- <div class="time">
          <span>医生：</span>
          <span class="lineTextBox" style="min-width: 80px"> </span>
        </div> -->
      </div>
    </div>
    <div class="contain">
      <h2><img src="../../assets/images/jl.png" alt="" />结论</h2>
      <div class="content">
        <div class="textBox">
          <p>
            您孩子处于{{
              age.years | ageName(info.sex || "")
            }}，身体和大脑发育重要阶段和关键时期
          </p>
          <p>
            <span>身高{{ hInfo.hText || "" }}：</span>当前身高{{
              hInfo.myHeight || ""
            }}cm，比标准身高{{ hInfo.normalHeight }}cm
            {{ hInfo.isMore ? "高" : "矮" }}
            {{ hInfo.chaHeight || "" }}cm，年长高 {{ hInfo.yearHeight || "" }}cm
          </p>
          <p>
            <span>体重{{ wInfo.wText || "" }}：</span>当前体重{{
              wInfo.myWeight || ""
            }}kg，比标准体重{{ wInfo.normalWeight || "" }}kg{{
              wInfo.isMore ? "重" : "轻"
            }}
            {{ wInfo.chaHeight || "" }}kg， 年增重
            {{ wInfo.yearWeight || "" }}公斤
          </p>
        </div>
      </div>
    </div>
    <div class="contain">
      <h2><img src="../../assets/images/suggest.png" alt="" />建议</h2>
      <div class="content">
        <div class="textBox">
          <div class="line" v-for="(item, index) in sugInfo.sug" :key="item">
            <em>{{ index + 1 }}、</em>
            <span>{{ item }}</span>
          </div>
        </div>
      </div>
    </div>
    <div class="suggestBox">
      <h3>
        <img src="../../assets/images/tips.png" alt="" style="width: 16px" />
        <span>温馨提示</span>
      </h3>
      <p>
        单纯依靠生活年龄以及父母身高来推测孩子的成年身高误差大，极易遗漏风险因素，建议结合骨龄来做身高评价，以免错失长高机会。
      </p>
    </div>
    <div class="pageBtnBox">
      <!-- <div class="btn" @click="downloadReport()">下载完整报告</div> -->
      <div class="btn btn2" @click="backPage()">返回主页面</div>
    </div>
  </div>
</template>

<script>
import suggest from "@/utils/suggest.js";
export default {
  props: {
    info: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      wInfo: {},
      hInfo: {},
      suggest,
      sugInfo: {},
      dataList: [
        {
          name: "扁平足",
          checked: false,
        },
        {
          name: "高足弓",
          checked: false,
        },
        {
          name: "足外翻",
          checked: false,
        },
        {
          name: "内外八",
          checked: false,
        },
        {
          name: "X型腿",
          checked: false,
        },
        {
          name: "O型腿",
          checked: false,
        },
        {
          name: "正常，足弓发育良好",
          checked: false,
        },
      ],
      age: {},
    };
  },
  components: {},
  methods: {
    backPage() {
      this.$emit("backPage");
    },
    downloadReport() {
      this.$emit("downloadReport");
    },
  },
  mounted() {
    //建议
    this.age = this.calcAge(this.info.age);
    if (this.info.isBianPingZu === "有") {
      this.dataList[0].checked = true;
    }
    if (this.info.isGaoZuGong === "有") {
      this.dataList[1].checked = true;
    }
    if (this.info.isZuWaiFan === "有") {
      this.dataList[2].checked = true;
    }
    if (this.info.isNeiWaiBa === "有") {
      this.dataList[3].checked = true;
    }
    if (this.info.isXTui === "有") {
      this.dataList[4].checked = true;
    }
    if (this.info.isOTui === "有") {
      this.dataList[5].checked = true;
    }
    if (this.info.isZuHuaiOk === "有") {
      this.dataList[6].checked = true;
    }
    this.hInfo = this.calcHeight(
      this.age.years,
      this.age.months,
      this.info.sex,
      this.info.height
    );
    this.wInfo = this.calcWeight(
      this.age.years,
      this.age.months,
      this.info.sex,
      this.info.weight
    );
    this.sugInfo = this.suggest[this.hInfo.hNum + "-" + this.wInfo.wNum];
    this.$emit("getSugInfo", this.sugInfo.details);
  },
};
</script>

<style scoped lang="less"></style>
