export default {
  eye1: {
    jlList: [
      "正常视力：无需佩戴眼镜或隐形眼镜来矫正视力。",
      "良好眼部健康：眼部健康状态良好，没有明显的屈光问题。",
      "无需矫正：在进行日常活动和驾驶时，不需要视力矫正措施。",
    ],
    jyList: [
      "保护眼睛：避免强光，防止紫外线伤害。在进行可能伤害眼睛的活动（如木工、化学实验等）时，佩戴适当的眼部保护装备避免眼部受伤。保持正确的坐姿，避免近距离接触电子设备等。",
      "定期检查视力：定期检查你的视力，以便及时发现任何可能的问题。建议每个月进行一次视力检查。如果注意到视力有任何变化，如模糊、重影或闪光，应立即咨询眼科医生。",
      "注意眼疲劳：避免长时间盯着电脑、手机屏幕或者阅读，遵循20-20-20规则：每20分钟看20英尺（6米）外的东西20秒。",
      "保持健康的生活方式：均衡的饮食、充足的睡眠、适度的运动和避免吸烟、二手烟等，保持眼睛健康。富含Omega-3脂肪酸、叶黄素、维生素A、C和E的食物对眼睛健康有益。",
    ],
  },
  eye2: {
    jlList: [
      "轻度屈光不正：低于正常水平，程度较轻，不会严重影响日常生活。",
      "远处视力模糊：在不佩戴矫正镜片的情况下，远处物体可能看起来模糊不清。",
      "近处视力较好：在阅读或进行近距离工作时可能视力较好。",
    ],
    jyList: [
      "视力矫正：佩戴眼镜或隐形眼镜，特别是观看或进行其他需要清晰远处视力的活动时。",
      "积极预防：建议每个月一次视力检查，监测视力变化和眼部健康，及时调整眼镜或隐形眼镜的度数。如果父母一方或者双方眼睛超过600度的，要积极预防高度近视。",
      "注意眼疲劳：保持正确的坐姿，避免近距离接触电子设备等。避免长时间进行可能导致眼部压力的活动，如长时间盯着电脑、手机或者阅读，遵循20-20-20规则：每20分钟看20英尺（6米）外的东西20秒。进行适当的眼部运动，如眼球转动和聚焦练习，增强眼肌。",
      "保持健康的生活方式：均衡的饮食、充足的睡眠、适度的运动和避免吸烟、二手烟，适当补充Omega-3脂肪酸、叶黄素、维生素A、C和E等营养品，对眼睛健康有益。",
    ],
  },
  eye3: {
    jlList: [
      "中度屈光不正：低于正常水平，可能会对日常生活和远距离视觉产生影响。",
      "远距离视力受限：在不佩戴矫正镜片的情况下，远处物体可能看起来模糊，会影响观看、运动和阅读远处标志的能力。",
      "近距离视力可能较好：在进行近距离阅读、观看时视力可能仍然较好。",
    ],
    jyList: [
      "视力矫正：佩戴眼镜或隐形眼镜来矫正视力，以改善远距离视觉和提高生活质量。",
      "积极预防：建议每个月一次视力检查，监测视力变化和眼部健康，及时调整眼镜或隐形眼镜的度数。如果父母一方或者双方眼睛超过600度的，要积极预防高度近视。",
      "避免剧烈运动：在剧烈运动时，可能会增加视网膜脱离的风险，要特别注意安全。",
      "眼部运动：进行适当的眼部运动，如眼球转动和聚焦练习，增强眼肌。",
      "注意眼疲劳：保持正确的坐姿，避免近距离接触电子设备等。避免长时间进行可能导致眼部压力的活动，如长时间盯着电脑、手机或者阅读，遵循20-20-20规则：每20分钟看20英尺（6米）外的东西20秒。",
      "保持健康的生活方式：均衡的饮食、充足的睡眠、适度的运动和避免吸烟、二手烟。保证摄入足够的Omega-3脂肪酸、叶黄素、维生素A、C和E等营养品，对眼睛健康有益。",
    ],
  },
  eye4: {
    jlList: [
      "严重屈光不正：显著低于正常水平，会对日常生活、工作和学习产生较大影响。",
      "远距离视力严重受限：在不佩戴矫正镜片的情况下，远处物体可能几乎无法看清。",
      "视网膜风险增加：高度近视患者可能有更高的风险发展为某些眼科疾病，如视网膜脱落、青光眼、白内障和黄斑病变。",
    ],
    jyList: [
      "视力矫正：佩戴眼镜或隐形眼镜来矫正视力，以改善远距离视觉和提高生活质量。对于高度近视，可能需要特殊类型的镜片或手术矫正。",
      "避免剧烈运动：在进行一些剧烈运动时，可能会增加视网膜脱离的风险，要特别注意安全。",
      "关注并发症：高度近视的人可能会有更高的风险患上一些眼部疾病，如青光眼、白内障和视网膜脱离等，定期到专业机构进行全面的眼部检查，以便及早发现和治疗任何潜在的问题。",
      "注意眼疲劳：保持正确的坐姿，避免近距离接触电子设备等。避免长时间进行可能导致眼部压力的活动，如长时间盯着电脑、手机或者阅读，遵循20-20-20规则：每20分钟看20英尺（6米）外的东西20秒。",
      "保持健康的生活方式：均衡的饮食、充足的睡眠、适度的运动和避免吸烟、二手烟。保证摄入足够的Omega-3脂肪酸、叶黄素、维生素A、C和E等营养品，对眼睛健康有益。",
    ],
  },
};
