<template>
  <div class="reportBox">
    <MyInfo
      @changeModel="changeModel"
      v-if="info.nl && page === 'myInfo'"
      :info="info"
    />
    <TgjcInfo
      v-if="info.nl"
      v-show="page === 'tgjcInfo'"
      :info="info"
      @getSugInfo="getSugInfo"
      @downloadReport="downloadReport"
      @backPage="backPage"
    />
    <KqjcInfo
      @backPage="backPage"
      @downloadReport="downloadReport"
      v-if="info.nl && page === 'kqjcInfo'"
      :info="info"
    />
    <SljcInfo
      @backPage="backPage"
      @downloadReport="downloadReport"
      v-if="info.nl && page === 'sljcInfo'"
      :info="info"
    />
    <div class="sugListBox">
      <div class="sugList" ref="textContainer">
        <h2>体检报告</h2>
        <div class="mybox" style="margin:20px 10px;">
          <div class="infoLine" style="margin-top: 0">
            <span>姓名：</span>
            <span class="lineTextBox">{{ info.nickName }}</span>
            <span>性别：</span>
            <span class="lineTextBox">{{ info.sex ? "女" : "男" }}</span>
          </div>
          <div class="infoLine">
            <span>生日：</span>
            <span class="lineTextBox">{{ info.age }}</span>
            <span>年龄：</span>
            <span class="lineTextBox">{{
              info.nl
            }}</span>
          </div>
        </div>
        <!-- 这里是你想要转换成图片的文本内容 -->
        <div v-for="(item, index) in sugList" :key="index">
          <h3>{{ item.title }}</h3>
          <p v-for="(x, index2) in item.list" :key="index2">
            {{ index2 + 1 }}、{{ x }}
          </p>
        </div>
      </div>
      <img
        v-if="imageUrl"
        :src="imageUrl"
        alt="Generated Image"
        style="width: 100%"
      />
    </div>
    <!-- <div class="contain">
      <h2>体态检查</h2>
      <div class="content">
        <div class="checkBox">
          脊柱侧弯：
          <div class="check" style="width: auto; min-width: 0">
            <img
              v-if="info.jiZhuCeWan === '无异常'"
              src="@/assets/images/checked.png"
              style="width: 20px"
              alt=""
            />
            <img
              v-else
              src="@/assets/images/check.png"
              style="width: 20px"
              alt=""
            />
            <span>无异常</span>
          </div>
          <div class="check" style="width: auto; min-width: 0">
            <img
              v-if="info.jiZhuCeWan === '轻度'"
              src="@/assets/images/checked.png"
              style="width: 20px"
              alt=""
            />
            <img
              v-else
              src="@/assets/images/check.png"
              style="width: 20px"
              alt=""
            />
            <span>轻度</span>
          </div>
          <div class="check" style="width: auto; min-width: 0">
            <img
              v-if="info.jiZhuCeWan === '中度'"
              src="@/assets/images/checked.png"
              style="width: 20px"
              alt=""
            />
            <img
              v-else
              src="@/assets/images/check.png"
              style="width: 20px"
              alt=""
            />
            <span>中度</span>
          </div>
          <div class="check" style="width: auto; min-width: 0">
            <img
              v-if="info.jiZhuCeWan === '重度'"
              src="@/assets/images/checked.png"
              style="width: 20px"
              alt=""
            />
            <img
              v-else
              src="@/assets/images/check.png"
              style="width: 20px"
              alt=""
            />
            <span>重度</span>
          </div>
          <div class="check" style="width: auto; min-width: 0">
            <img
              v-if="info.jiZhuCeWan === '极重度'"
              src="@/assets/images/checked.png"
              style="width: 20px"
              alt=""
            />
            <img
              v-else
              src="@/assets/images/check.png"
              style="width: 20px"
              alt=""
            />
            <span>极重度</span>
          </div>
        </div>
        <div class="checkBox">
          其他体态：
          <div class="check" style="width: auto; min-width: 0">
            <img
              v-if="info.isGaoDiJian !== '无'"
              src="@/assets/images/checked.png"
              style="width: 20px"
              alt=""
            />
            <img
              v-else
              src="@/assets/images/check.png"
              style="width: 20px"
              alt=""
            />
            <span>高低肩</span>
          </div>
          <div class="check" style="width: auto; min-width: 0">
            <img
              v-if="info.isGuPenQianQing !== '无'"
              src="@/assets/images/checked.png"
              style="width: 20px"
              alt=""
            />
            <img
              v-else
              src="@/assets/images/check.png"
              style="width: 20px"
              alt=""
            />
            <span>骨盆前倾</span>
          </div>
          <div class="check" style="width: auto; min-width: 0">
            <img
              v-if="info.isQiTaZhengZhuang !== '无'"
              src="@/assets/images/checked.png"
              style="width: 20px"
              alt=""
            />
            <img
              v-else
              src="@/assets/images/check.png"
              style="width: 20px"
              alt=""
            />
            <span>其他症状</span>
          </div>
          <div class="check" style="width: auto; min-width: 0">
            <img
              v-if="info.isTiTaiOk !== '无'"
              src="@/assets/images/checked.png"
              style="width: 20px"
              alt=""
            />
            <img
              v-else
              src="@/assets/images/check.png"
              style="width: 20px"
              alt=""
            />
            <span>体态良好</span>
          </div>
        </div>
        <div class="time">
          <span>医生：</span>
          <span class="lineTextBox" style="min-width: 80px"> </span>
        </div>
      </div>
    </div> -->
  </div>
</template>

<script>
import html2canvas from "html2canvas";
import MyInfo from "./components/myInfo.vue";
import TgjcInfo from "./components/tgjcInfo.vue";
import KqjcInfo from "./components/kqjcInfo.vue";
import SljcInfo from "./components/sljcInfo.vue";
export default {
  components: {
    MyInfo,
    TgjcInfo,
    KqjcInfo,
    SljcInfo,
  },
  name: "Login",
  data() {
    return {
      imageUrl: "",
      page: "myInfo",
      info: {
        conclusion: "", //结论
        suggest: "", //建议
        nl: "",
        createTime: "",
      },
      sugList: [],
    };
  },
  async mounted() {
    const { name, phone } = this.$route.query;
    const res = await this.getAjax(
      `api/wx/user/base/searchByPhoneAndName`,
      {
        name,
        phone,
      },
      "post"
    );
    this.info = res.data;
    const years =
      new Date().getFullYear() - new Date(this.info.age).getFullYear();
    this.info.nl = years;
  },
  methods: {
    getSugInfo(data) {
      const list = [];
      Object.keys(data).forEach((e) => {
        list.push({
          title: e,
          list: data[e],
        });
      });
      this.sugList = list;
    },

    async downloadReport() {
      //下载图片
      try {
        const canvas = await html2canvas(this.$refs.textContainer);
        this.imageUrl = canvas.toDataURL("image/png");
        const link = document.createElement("a");
        link.href = this.imageUrl;
        link.download = "体检报告";
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      } catch (error) {
        this.toast("生成图片失败");
      }
    },
    changeModel(opt) {
      this.page = opt;
    },
    backPage() {
      this.page = "myInfo";
    },
  },
};
</script>

<style lang="less"></style>
