<template>
  <div class="myInfo">
    <div class="reportTitle">儿童成长发育报告</div>
    <div class="time">
      <span>报告时间：</span>
      <span class="lineTextBox">{{
        info.createTime.replace("T", " ").slice(0, 10)
      }}</span>
    </div>
    <div class="contain">
      <h2><img src="../../assets//images/info.png" alt="" />个人信息</h2>
      <div class="content">
        <div class="infoLineBox">
          <img
            v-if="info.sex"
            src="../../assets/images/girl.png"
            style="width: 80px"
            alt=""
          />
          <img
            v-else
            src="../../assets/images/boy.png"
            style="width: 80px"
            alt=""
          />
          <div class="mybox">
            <div class="infoLine" style="margin-top: 0">
              <span>姓名：</span>
              <span class="lineTextBox">{{ info.nickName }}</span>
              <span>性别：</span>
              <span class="lineTextBox">{{ info.sex ? "女" : "男" }}</span>
            </div>
            <div class="infoLine">
              <span>生日：</span>
              <span class="lineTextBox">{{ info.age }}</span>
              <span>年龄：</span>
              <span class="lineTextBox" style="width: 35px; flex: none">{{
                info.nl
              }}</span>
            </div>
          </div>
        </div>

        <div class="infoLine">
          <span>父亲身高：</span>
          <span class="lineTextBox">{{ info.fatherHeight }}cm</span>
          <span>母亲身高：</span>
          <span class="lineTextBox">{{ info.motherHeight }}cm</span>
        </div>
        <div class="infoLine">
          <span>遗传身高：</span>
          <span class="lineTextBox">{{ info.yichuanHeight }}cm ± 5cm</span>
        </div>
        <div class="infoLine">
          <span>学校班级：</span>
          <span class="lineTextBox">
            <!-- {{ info.province }}{{ info.city }}{{ info.area }} -->
            {{ info.school }}{{ info.classLevel }}{{ info.classField }}</span
          >
        </div>
      </div>
    </div>
    <div class="btnBox">
      <div class="btn" @click="changeModel('tgjcInfo')">
        <img src="@/assets/images/tg.png" alt="" />
        <p>点击获取体格发育报告</p>
      </div>
      <div class="btn" @click="changeModel('kqjcInfo')">
        <img src="@/assets/images/tee.png" alt="" />
        <p>点击获取口腔体检报告</p>
      </div>
      <div class="btn" @click="changeModel('sljcInfo')">
        <img src="@/assets/images/eyes.png" alt="" />
        <p>点击获取视力体检报告</p>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    info: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {};
  },
  components: {},
  methods: {
    changeModel(opt) {
      this.$emit("changeModel", opt);
    },
  },
  mounted() {},
};
</script>

<style scoped lang="less">
.myInfo {
  .table {
    width: 100%;
  }
  .infoLineBox {
    display: flex;
    align-items: center;
    .mybox {
      flex: 1;
      margin-left: 5px;
    }
  }
  .btnBox {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 50px;
    padding: 0 10px;
    .btn {
      width: 90px;
      height: 87px;
      background: url("../../assets/images/btn_bg.png") no-repeat;
      display: block;
      background-size: cover;
      cursor: pointer;
      img {
        width: 30px;
        display: block;
        margin: 10px auto 5px;
      }
      p {
        padding: 0 5px;
        text-align: center;
        font-size: 12px;
        line-height: 1.2;
      }
    }
  }
  .textBox {
    min-height: 80px;
    word-wrap: break-word;
    word-break: break-all;
  }
  .checkBox {
    display: flex;
    flex-wrap: wrap;
    line-clamp: 3;
    .check {
      display: flex;
      align-items: center;
      min-width: 33.33%;
      margin-bottom: 15px;
      span {
        margin-left: 3px;
      }
    }
  }
}
</style>
