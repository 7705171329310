<template>
  <div class="myPage">
    <div class="reportTitle">口腔检查报告</div>
    <div class="contain">
      <h2><img src="../../assets//images/kq.png" alt="">口腔检查</h2>
      <div class="content">
        <div class="infoLine">
          <span>口腔内牙齿总数：</span>
          <span class="lineTextBox" style="min-width: 60px; flex: 0">{{
            info.teethCounts
          }}</span>
          <span>颗</span>
        </div>
        <div class="checkBox" style="margin-top: 20px">
          <div class="check" v-for="item in dataList" :key="item.name">
            <img
              v-if="item.checked"
              src="@/assets/images/checked.png"
              style="width: 20px"
              alt=""
            />
            <img
              v-else
              src="@/assets/images/check.png"
              style="width: 20px"
              alt=""
            />
            <span>{{ item.name }}</span>
          </div>
        </div>
        <div class="time">
          <span>医生：</span>
          <span class="lineTextBox" style="min-width: 80px"> </span>
        </div>
      </div>
    </div>
    <div class="contain">
      <h2><img src="../../assets//images/suggest.png" alt="">诊疗建议</h2>
      <div class="content">
        <div class="checkBox" style="margin-top: 20px">
          <div class="check" v-for="item in dataList2" :key="item.name">
            <img
              v-if="item.checked"
              src="@/assets/images/checked.png"
              style="width: 20px"
              alt=""
            />
            <img
              v-else
              src="@/assets/images/check.png"
              style="width: 20px"
              alt=""
            />
            <span>{{ item.name }}</span>
          </div>
        </div>
      </div>
    </div>
    <div class="contain">
      <h2><img src="../../assets//images/suggest.png" alt="">口腔健康状况评价</h2>
      <div class="content">
        <div class="checkBox checkBox4" style="margin-top: 20px">
          <div class="check" v-for="item in dataList3" :key="item.name">
            <img
              v-if="item.checked"
              src="@/assets/images/checked.png"
              style="width: 20px"
              alt=""
            />
            <img
              v-else
              src="@/assets/images/check.png"
              style="width: 20px"
              alt=""
            />
            <span>{{ item.name }}</span>
          </div>
        </div>
        <div class="time">
          <span>报告时间：</span>
          <span class="lineTextBox" style="min-width: 80px"> </span>
          <span>医生：</span>
          <span class="lineTextBox" style="min-width: 80px"> </span>
        </div>
      </div>
    </div>
    <div class="suggestBox">
      <h3>
        <img src="../../assets/images/tips.png" alt="" style="width: 16px" />
        <span>温馨提示</span>
      </h3>
      <p>
        本报告仅为初诊断结果，不作为确诊依据。确诊请前往体检中心进一步诊断。
      </p>
    </div>
    <div class="pageBtnBox">
      <!-- <div class="btn" @click="downloadReport()">下载完整报告</div> -->
      <div class="btn btn2" @click="backPage()">返回主页面</div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    info: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      dataList: [
        //口腔检查
        {
          name: "龋齿",
          checked: false,
        },
        {
          name: "牙菌斑",
          checked: false,
        },
        {
          name: "乳牙滞留",
          checked: false,
        },
        {
          name: "牙列不齐",
          checked: false,
        },
        {
          name: "咬合不正",
          checked: false,
        },
        {
          name: "牙龈炎",
          checked: false,
        },
        {
          name: "无异常",
          checked: false,
        },
      ],
      dataList2:[
        {
          name:'牙齿矫正',
          checked:false
        },{
          name:'补牙',
          checked:false
        },{
          name:'窝沟封闭',
          checked:false
        },{
          name:'洁牙',
          checked:false
        },{
          name:'拔牙',
          checked:false
        },{
          name:'拍牙片',
          checked:false
        },{
          name:'涂氟',
          checked:false
        },{
          name:'其他',
          checked:false
        }
      ],
      dataList3:[
        {
          name:'优',
          checked:false
        },{
          name:'良',
          checked:false
        },{
          name:'一般',
          checked:false
        },{
          name:'差',
          checked:false
        }
      ]
    };
  },
  components: {},
  methods: {
    downloadReport(){
      this.$emit('downloadReport');
    },
    backPage(){
      this.$emit("backPage");
    }
  },
  mounted() {
    //分界

    if (this.info.isQuChi === "有") {
      this.dataList[0].checked = true;
    }
    if (this.info.isYaJunBan === "有") {
      this.dataList[1].checked = true;
    }
    if (this.info.isRuYaZhiLiu === "有") {
      this.dataList[2].checked = true;
    }
    if (this.info.isYaLieBuQi === "有") {
      this.dataList[3].checked = true;
    }
    if (this.info.isYaoHeBuZhen === "有") {
      this.dataList[4].checked = true;
    }
    if (this.info.isYaYinYan === "有") {
      this.dataList[5].checked = true;
    }

    // --------
    if (this.info.jyYaChiJiaoZheng === "是") {
      this.dataList2[0].checked = true;
    }
    if (this.info.jyBuYa === "是") {
      this.dataList2[1].checked = true;
    }
    if (this.info.jyWoGouFengBi === "是") {
      this.dataList2[2].checked = true;
    }
    if (this.info.jyJieYa === "是") {
      this.dataLis2t[3].checked = true;
    }
    if (this.info.jyBaYa === "是") {
      this.dataList2[4].checked = true;
    }
    if (this.info.jyPaiYaPian === "是") {
      this.dataList2[5].checked = true;
    }
    if (this.info.jyTuFu === "是") {
      this.dataList2[6].checked = true;
    }
    if (this.info.jyYaQiTa === "是") {
      this.dataList2[6].checked = true;
    }

    // --------
    if (this.info.kqYou === "是") {
      this.dataList3[0].checked = true;
    }
    if (this.info.kqLiang === "是") {
      this.dataList3[1].checked = true;
    }
    if (this.info.kqYiBan === "是") {
      this.dataList3[2].checked = true;
    }
    if (this.info.kqCha === "是") {
      this.dataList3[3].checked = true;
    }

    const result = this.dataList.every((e) => !e.checked);
    this.dataList[6].checked = result;
  },
};
</script>

<style scoped lang="less"></style>
