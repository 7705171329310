<template>
  <div class="myPage">
    <div class="reportTitle">视力检查报告</div>
    <div class="contain">
      <h2><img src="../../assets/images/eye.png" alt="" />视力检查</h2>
      <div class="content">
        <el-table class="table" :data="tableData">
          <el-table-column prop="name" label="" width="60" />
          <el-table-column prop="vision" align="center" label="裸眼视力" />
          <el-table-column prop="axisLength" align="center" width="90">
            <template slot="header">
              <div>眼轴长度</div>
              <div>（mm）</div>
            </template>
          </el-table-column>
          <el-table-column prop="diopter" align="center" width="80">
            <template slot="header">
              <div>屈光度</div>
              <div>（D）</div>
            </template>
          </el-table-column>
        </el-table>
        <!-- <div class="time">
          <span>医生：</span>
          <span class="lineTextBox" style="min-width: 80px"> </span>
        </div> -->
      </div>
    </div>
    <div class="contain">
      <h2><img src="../../assets/images/jl.png" alt="" />结论</h2>
      <div class="content">
        <div class="textBox">
          <p v-for="(item, index) in jlList" :key="item">
            {{ index + 1 }}、{{ item }}
          </p>
        </div>
      </div>
    </div>
    <div class="contain">
      <h2><img src="../../assets/images/suggest.png" alt="" />建议</h2>
      <div class="content">
        <div class="textBox">
          <p v-for="(item, index) in jyList" :key="item">
            {{ index + 1 }}、{{ item }}
          </p>
        </div>
      </div>
    </div>
    <div class="suggestBox">
      <h3>
        <img src="../../assets/images/tips.png" alt="" style="width: 16px" />
        <span>温馨提示</span>
      </h3>
      <p>
        本报告仅为初诊断结果，不作为确诊依据。确诊请前往体检中心进一步诊断。
      </p>
    </div>
    <div class="pageBtnBox">
      <!-- <div class="btn" @click="downloadReport()">下载完整报告</div> -->
      <div class="btn btn2" @click="backPage()">返回主页面</div>
    </div>
  </div>
</template>

<script>
import eyesSug from "@/utils/eyesSug.js";
export default {
  props: {
    info: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      eyesSug,
      jlList: [], //结论
      jyList: [], //建议
      tableData: [
        {
          name: "左眼",
          vision: "",
          axisLength: "",
          diopter: "",
        },
        {
          name: "右眼",
          vision: "",
          axisLength: "",
          diopter: "",
        },
      ],
    };
  },
  components: {},
  methods: {
    downloadReport() {
      this.$emit("downloadReport");
    },
    backPage() {
      this.$emit("backPage");
    },
  },
  mounted() {
    this.tableData[0].vision = parseFloat(
      this.info.leftNakedEyeVision || 0
    ).toFixed(1);
    this.tableData[0].diopter = parseFloat(this.info.leftDiopter || 0).toFixed(
      1
    );
    this.tableData[0].axisLength = parseFloat(
      this.info.leftAxisLength || 0
    ).toFixed(1);
    this.tableData[1].vision = parseFloat(
      this.info.rightNakedEyeVision || 0
    ).toFixed(1);
    this.tableData[1].diopter = parseFloat(this.info.rightDiopter || 0).toFixed(
      1
    );
    this.tableData[1].axisLength = parseFloat(
      this.info.rightAxisLength || 0
    ).toFixed(1);

    // 获取最差视力
    console.log(this.eyesSug, "eyesSug");
    const sl =
      this.tableData[0].vision < this.tableData[1].vision
        ? this.tableData[0].vision
        : this.tableData[1].vision;

    if (sl > 4.9) {
      this.jlList = this.eyesSug["eye1"].jlList;
      this.jyList = this.eyesSug["eye1"].jyList;
    } else if (sl > 4.5 && sl <= 4.9) {
      this.jlList = this.eyesSug["eye2"].jlList;
      this.jyList = this.eyesSug["eye2"].jyList;
    } else if (sl > 4.1 && sl <= 4.5) {
      this.jlList = this.eyesSug["eye3"].jlList;
      this.jyList = this.eyesSug["eye3"].jyList;
    } else {
      this.jlList = this.eyesSug["eye4"].jlList;
      this.jyList = this.eyesSug["eye4"].jyList;
    }
  },
};
</script>

<style scoped lang="less">
.textBox {
  p {
    line-height: 1.8;
  }
}
</style>
